<app-query-printer></app-query-printer>
<div style="padding: 1em" *ngIf="this.isFound">
  <h1>Specimen</h1>
  <h2>{{ this.current_specimen.registration_number }}</h2>

  <div class="btn-group-sm float-end" *ngIf="this.allow_browse">
    <a id="button-left" class="btn btn-light btn-sm" (click)="this.browse('left')" title="<-">
      <mat-icon fontIcon="navigate_before"></mat-icon>
    </a>
    <a class="btn btn-light btn-sm">{{ this.shown_index }}/{{ this.helper.specimen_list.length }}</a>
    <a id="button-right" class="btn btn-light btn-sm" (click)="this.browse('right')" title="->">
      <mat-icon fontIcon="navigate_next"></mat-icon>
    </a>
  </div>

  <hr />

  {{ "results.cite_as" | translate }}:
  <!-- Show the citation within a standalone form -->
  <form class="cite-as-form">
    <mat-form-field style="width: 100%" appearance="outline">
      <input
        matInput
        readonly="true"
        class="citation-url"
        [(ngModel)]="this.current_specimen.cite_as"
        [ngModelOptions]="{ standalone: true }" />
    </mat-form-field>
  </form>

  <h4 class="pt-2">Details</h4>
  <p>
    <b>{{ this.current_specimen.source_system_name }}</b> ({{ this.current_specimen.source_system_code }})
  </p>

  <table class="table table-striped">
    <tbody>
      <tr>
        <th class="col-4">
          {{ "search.scientific_name" | translate }}
        </th>
        <td class="col-8">
          <ng-container *ngFor="let identification of this.current_specimen.identifications">
            <div
              *ngIf="
                this.nba.get_taxon_existence(identification.scientificName.scientificNameGroup);
                else taxon_does_not_exist
              ">
              <a
                id="cy-scientific-name-link"
                class="a-no-redirect"
                (click)="this.utility.navigate_to('/taxon', identification.scientificName.scientificNameGroup)">
                {{ identification.scientificName.fullScientificName }}
              </a>
            </div>
            <ng-template #taxon_does_not_exist>
              <div>
                {{ identification.scientificName.fullScientificName }}
              </div>
            </ng-template>
          </ng-container>
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.vernaculars" | translate }}
        </th>
        <ng-container
          *ngIf="
            this.current_specimen.has_vernaculars();
            then vernacular_content;
            else vernacular_empty
          "></ng-container>
        <ng-template #vernacular_content>
          <td>
            <ng-container *ngFor="let vernacular of this.current_specimen.identifications[0].vernacularNames">
              <p>{{ vernacular.name }}</p>
            </ng-container>
          </td>
        </ng-template>
        <ng-template #vernacular_empty>
          <td>—</td>
        </ng-template>
      </tr>

      <tr>
        <th>Vernaculars from associated taxon</th>
        <td>—</td>
      </tr>

      <tr>
        <th>
          {{ "search.registration_number" | translate }}
        </th>
        <td id="cy_registration_number">
          {{ this.current_specimen.registration_number }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.source" | translate }}
        </th>
        <td id="cy_source_name">
          {{ this.current_specimen.source_system_name }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.license" | translate }}
        </th>
        <td>
          {{ this.current_specimen.license }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.institution" | translate }}
        </th>
        <td>
          {{ this.current_specimen.institution }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.collection_name" | translate }}
        </th>
        <td>
          {{ this.current_specimen.collection_name }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.record_basis" | translate }}
        </th>
        <td id="cy_record_basis">
          {{ this.current_specimen.record_basis }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.type_status" | translate }}
        </th>
        <td>
          {{ this.current_specimen.type_status }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.phase_stage" | translate }}
        </th>
        <td>
          {{ this.current_specimen.phase_stage }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.sex" | translate }}
        </th>
        <td>
          {{ this.current_specimen.sex }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.part" | translate }}
        </th>
        <td>
          {{ this.current_specimen.part }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.preparation_method" | translate }}
        </th>
        <td>
          {{ this.current_specimen.preparation_method }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.number_of_specimen" | translate }}
        </th>
        <td>
          {{ this.current_specimen.number_of_specimen }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.date" | translate }}
        </th>
        <td>
          {{ this.current_specimen.gathering_event_date }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.collector" | translate }}
        </th>
        <!-- Only show collectors if there are collectors available -->
        <ng-container
          *ngIf="
            this.utility.is_empty_array(this.current_specimen.gathering_event_gathering_persons);
            then collectors_empty;
            else collectors_content
          "></ng-container>
        <ng-template #collectors_content>
          <td>
            <ng-container *ngFor="let item of this.current_specimen.gathering_event_gathering_persons">
              <p>{{ item.fullName }}</p>
            </ng-container>
          </td>
        </ng-template>
        <ng-template #collectors_empty>
          <td>—</td>
        </ng-template>
      </tr>

      <tr>
        <th>
          {{ "search.locality" | translate }}
        </th>
        <td>{{ this.current_specimen.formatted_locality }}</td>
      </tr>

      <tr>
        <th>Site coordinates</th>

        <ng-container
          *ngIf="this.current_specimen.position === undefined; then no_coordinates; else coordinates"></ng-container>
        <ng-template #coordinates>
          <td>
            <ng-container *ngFor="let coordinates of this.current_specimen.gathering_event_site_coordinates">
              <p>({{ coordinates.latitudeDecimal }}, {{ coordinates.longitudeDecimal }})</p>
            </ng-container>
          </td>
        </ng-template>
        <ng-template #no_coordinates>
          <td>—</td>
        </ng-template>
      </tr>

      <tr>
        <th>
          {{ "search.collectors_field_number" | translate }}
        </th>
        <td>
          {{ this.current_specimen.collectors_field_number }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.modified" | translate }}
        </th>
        <td>
          {{ this.current_specimen.modified }}
        </td>
      </tr>

      <tr>
        <th>
          {{ "search.previous_units_text" | translate }}
        </th>
        <td>
          {{ this.current_specimen.previous_units_text }}
        </td>
      </tr>
    </tbody>
  </table>

  <hr />

  <ng-container *ngIf="!this.utility.is_empty_array(this.current_specimen.assemblage)">
    <h4>{{ "specimen.assemblage" | translate }}</h4>
    <table class="table table-striped" id="cy_assemblage_table">
      <tbody>
        <tr
          *ngFor="let assemblage of this.current_specimen.assemblage"
          (click)="this.request_assemblage_item(assemblage.item.sourceSystemId)"
          class="highlight-row"
          id="cy_assemblage_row">
          <td>
            <a class="a-no-redirect">
              {{ assemblage.item.sourceSystemId }}
            </a>
          </td>
          <td>
            {{ assemblage.item.collectionType }}
          </td>
        </tr>
      </tbody>
    </table>
    <hr />
  </ng-container>

  <h4>Feedback</h4>
  <p>
    {{ "text.specimen_inaccuracies" | translate
    }}<a href="mailto:collectie@naturalis.nl">collectie&#64;naturalis.nl</a>.
  </p>

  <hr />

  <ng-container *ngIf="this.multimedia_data.totalSize > 0">
    <h4>Multimedia</h4>
    <app-multimedia-viewer
      id="cy_multimedia_list"
      (multimedia_item_request)="this.navigator.navigate_to_multimedia_item($event)"
      [images]="this.multimedia_data.result_set"></app-multimedia-viewer>
    <hr />
  </ng-container>

  <!-- Check if we have a position. If so, show the map -->
  <ng-container *ngIf="this.current_specimen.has_position()">
    <h4>
      {{ this.utility.capitalize_first_letter("miscellaneous.map" | translate) }}
    </h4>

    <!-- Show a little map with the location of the specimen -->
    <div class="row" fxLayout="column" id="cy_google_map">
      <!-- Let the map scale with a flex div -->
      <div class="map" fxFlex="1 1 100%" #map>
        <google-map
          height.px="map.offsetHeight"
          width="auto"
          [center]="this.current_specimen.position"
          [zoom]="this.map_zoom"
          [options]="this.map_options">
          <map-marker
            *ngFor="let marker_position of this.map_marker_positions"
            [position]="marker_position"
            [options]="this.map_marker_options">
          </map-marker>
        </google-map>
      </div>
    </div>
  </ng-container>
</div>
<div style="padding: 1em" *ngIf="this.isFound === false">
  <h1>{{ "specimen.not_found_title" | translate }}</h1>
  <p>{{ "specimen.not_found_body" | translate }}</p>
</div>
